import React from "react"
import ReactPlayer from "react-player"
import { Image } from "../VindiciaComponents"

const CampaignPromo = ({ data }) => {
  const section = data.primary
  const { section_image, section_vimeo_id, section_vimeo_height } = section
  const hasImage = section_image && section_image.fluid ? true : undefined

  const videoHeight = Number(section_vimeo_height && section_vimeo_height.toString()) || 500

  return (
    <section
      id={section.section_id}
      className={`uk-section cta_block`}
      style={{ background: section.section_background }}
    >
      <div className="uk-container uk-container-small uk-text-center">
        {section.title && (
          <div
            className="uk-text-center"
            dangerouslySetInnerHTML={{ __html: section.title.html }}
          />
        )}
        {section.description && (
          <div
            className="uk-text-center"
            dangerouslySetInnerHTML={{ __html: section.description.html }}
          />
        )}
        <a
          className="uk-button uk-button-primary uk-border-pill"
          href={section.cta_button_link.url}
          target={section.cta_button_link.target}
        >
          {section.cta_button_text}
        </a>
      </div>
      <div className="uk-container uk-flex uk-flex-column uk-flex-middle">
        {section_vimeo_id && (
          <div
            className="vimeo-container uk-width-1-1 uk-margin-top"
            data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
          >
            <div className="uk-width-1-1" style={{ height: "500px" }}>
              <iframe
                // src="https://player.vimeo.com/video/252443587?autoplay=1&muted=1&dnt=1&controls=1"
                src={`https://player.vimeo.com/video/${section_vimeo_id}?autoplay=1&muted=1&dnt=1&controls=1`}
                className="optanon-category-C0004 uk-width-1-1 uk-height-1-1"
              ></iframe>
            </div>
            {/*<ReactPlayer*/}
            {/*  url={`https://vimeo.com/${section_vimeo_id}`}*/}
            {/*  controls={true}*/}
            {/*  width={"100%"}*/}
            {/*  height={videoHeight}*/}
            {/*/>*/}
          </div>
        )}
        {hasImage && (
          <div
            className="vimeo-container uk-margin-top"
            data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
          >
            <Image {...section_image.fluid} />
          </div>
        )}
      </div>
    </section>
  )
}

export default CampaignPromo
