import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import MiniSiteNavigation from "./MiniSite/Navigation"

const MiniSiteLayout = ({ children }) => {
  useEffect(() => {
    try {
      const UIkit = require("uikit/dist/js/uikit")
      const Icons = require("uikit/dist/js/uikit-icons")
      UIkit.use(Icons)
    } catch (e) {
      console.error("UIKit ERROR", e)
    }
  }, [])

  const data = useStaticQuery(graphql`
    query siteTitleQueryAndSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet title={data.site.siteMetadata.title}>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta
          name="google-site-verification"
          content="IRdl643QG669GnneNd_Cvcu3MSutVoYsCtOKDWVe94Q"
        ></meta>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <script
          type="text/javascript"
          src="https://cdn.cookielaw.org/consent/bff98548-2879-451a-90c1-638a780999b1/OtAutoBlock.js"
        />
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="bff98548-2879-451a-90c1-638a780999b1"
        />
      </Helmet>
      <MiniSiteNavigation />
      <main>{children}</main>
      <footer></footer>
    </>
  )
}

MiniSiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MiniSiteLayout
