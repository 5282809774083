import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"
import Hero from "../components/Hero/Hero"
import ContentType from "../components/CustomTemplate/CustomContentType"

const Video = ({ data }) => {
  const { uid, data: pageData } = data.prismicVideo

  return (
    <Layout>
      <SEO
        title={pageData.seo_title}
        description={pageData.seo_description}
        keywords={pageData.seo_keywords}
      />
      <div className="default-theme custom-template">
        <Hero page={uid} data={{ ...pageData, noHero: true }} />
        {pageData.body.map(section => {
          return <ContentType key={`section-${section.primary.section_id}`} section={section} />
        })}
      </div>
    </Layout>
  )
}

export default withPreview(Video)
export const VideoDetail = graphql`
  query VideoDetail($uid: String) {
    prismicVideo(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        color_theme
        hero_banner_image {
          alt
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        hero_title {
          text
          html
        }
        url_prefix
        body {
          ... on PrismicVideoBodyVideoComponent {
            primary {
              main_column_title {
                html
              }
              main_video_description {
                html
              }
              secondary_column_title {
                html
              }
              section_id
              section_name
              vimeo_id_video
            }
            items {
              video_image {
                alt
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              video_title {
                html
              }
              video_link {
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicVideoBodyRelatedMaterialComponent {
            primary {
              section_id
              section_name
              section_title {
                html
              }
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
              }
              item_doc_title {
                html
              }
              link_item {
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicVideoBodyCtaBlock {
            primary {
              section_id
              section_theme
              title {
                html
              }
              description {
                html
              }
              cta_button_link {
                target
                url
              }
              cta_button_text
              section_name
              section_background
              section_vimeo_id
              section_vimeo_height
              section_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicVideoBody2ColumnVideo {
            id
            slice_label
            slice_type
            primary {
              copy {
                html
              }
              section_id
              section_name
              section_theme
              section_background_color
              vimeo_id
            }
          }
          ... on PrismicVideoBody2Column {
            primary {
              section_id
              section_name
              section_theme
              section_background_color
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              cta_url {
                target
                url
              }
              cta_text
            }
            slice_label
            slice_type
          }
          ... on PrismicVideoBodyFullWidthBanner {
            primary {
              section_id
              section_name
              copy {
                html
              }
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              cta_url {
                url
                target
              }
              cta_text
            }
            slice_type
          }
          ... on PrismicVideoBody3ColumnCards {
            primary {
              section_id
              section_name
              section_title_centered {
                text
              }
            }
            items {
              card_body_content {
                html
              }
              card_header_content {
                html
              }
              card_header_image {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicVideoBody1ColumnContainerSmall {
            primary {
              section_id
              section_name
              copy {
                html
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicVideoBody3ColumnWithIcon {
            primary {
              section_id
              section_name
            }
            items {
              icon_image {
                fixed(height: 50) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              short_copy {
                html
              }
              cta_link_url {
                target
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicVideoBody3ColumnGrid {
            primary {
              section_id
              section_name
              section_title {
                text
              }
            }
            items {
              sub_mgmt_items {
                uid
                document {
                  ... on PrismicSubscriptionManagement {
                    data {
                      short_copy {
                        html
                      }
                      short_title
                      icon_image {
                        fixed(height: 60) {
                          ...GatsbyPrismicImageFixed_noBase64
                        }
                      }
                      url_prefix
                      second_url_prefix
                    }
                  }
                }
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicVideoBody2ColumnGeneric {
            id
            primary {
              section_id
              section_name
              column_one {
                html
              }
              column_two {
                html
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicVideoBodyGenericCodeEmbed {
            primary {
              code
              section_id
              section_name
            }
            slice_label
            slice_type
          }
          ... on PrismicVideoBody2ColumnWithAccordion {
            primary {
              section_id
              section_name
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_label
            slice_type
            items {
              accordion_element_body {
                html
              }
              accordion_element_title
            }
          }
          ... on PrismicVideoBodySubscribeplans {
            primary {
              section_id
              section_name
              section_title {
                html
              }
            }
            slice_label
            slice_type
            items {
              card_header_content {
                html
                raw
              }
              card_body_content {
                html
                raw
              }
            }
          }
          ... on PrismicVideoBodyFullWidthBannerWithVideoOverlay {
            primary {
              section_id
              section_name
              copy {
                html
              }
              video_id
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicVideoBodyScrollerWithBg {
            id
            slice_type
            primary {
              section_name
              section_id
              background_image {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            items {
              slide_title
              slide_copy {
                html
              }
              slide_image_desktop {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              slide_image_mobile {
                alt
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicVideoBodyPromotionComponent {
            primary {
              section_id
              section_name
              section_promo_title
              section_description {
                html
              }
            }
            slice_type
            items {
              item_title
              item_description {
                html
              }
              button_link {
                target
                url
              }
              button_label
              item_image {
                alt
                fluid(maxWidth: 760) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicVideoBodyImageGallery {
            primary {
              section_id
              section_name
              section_description {
                html
              }
            }
            slice_type
            items {
              item_description {
                html
              }
              item_image {
                alt
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicVideoBodyNumberFact {
            primary {
              section_id
              section_name
              section_title {
                html
              }
              section_description {
                html
              }
            }
            slice_type
            items {
              number_field
              prefix_text {
                html
                raw
              }
              postfix_text {
                html
                raw
              }
              description_text {
                html
              }
            }
          }
        }
      }
      uid
      last_publication_date
    }
  }
`
