import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import SEO, { seoPropsMapping } from "../components/seo"
import moment from "moment"
import { withPreview } from "gatsby-source-prismic"

const PressRelease = ({ data }) => {
  const {
    short_title,
    publish_date,
    article_content,
    tile_image_for_press_list,
  } = data.prismicPressReleaseEntry.data

  const seoProps = seoPropsMapping(data.prismicPressReleaseEntry.data, {
    seo_title: short_title,
    seo_description: article_content && article_content.text,
    image: tile_image_for_press_list.fixed && tile_image_for_press_list.fixed.src,
  })

  return (
    <Layout>
      <SEO {...seoProps} />
      <Header title={`Press Releases`} />
      <section className="uk-section">
        <div className="uk-container">
          <div className="">
            <p className="uk-heading-small">{short_title}</p>
            <p>{moment(publish_date).format("MMM D, YYYY")}</p>
            <div className="uk-width-expand">
              <div
                className="prismic-content uk-margin-small-top"
                dangerouslySetInnerHTML={{ __html: article_content.html }}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(PressRelease)
export const pressReleaseQuery = graphql`
  query pressReleaseQuery($uid: String) {
    prismicPressReleaseEntry(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        canonical_url {
          url
        }
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        short_title
        publish_date
        article_content {
          html
          text
        }
        tile_image_for_press_list {
          fixed(width: 500) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
      }
    }
  }
`
