import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Image } from "../VindiciaComponents"
// import logo from "../../images/vindicia-logo.png"
// import darkLogo from "../../images/vindicia-logo-dark.png"
import logo from "../../images/vindicia_amdocs_white.png"
import darkLogo from "../../images/vindicia_amdocs.png"
//import logo from "../../images/vindicia-pride-logo.png"

const MiniSiteNavigation = () => {
  const [isVisible, setIsVisible] = useState(true)

  const handleScroll =
    typeof window !== undefined
      ? () => (window.scrollY === 0 ? setIsVisible(true) : setIsVisible(false))
      : null

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <>
      <div id="top-navigation">
        <div
          className={`nav ${isVisible ? "Animate" : "ReAnimate"} light`}
          data-uk-sticky="cls-active: uk-box-shadow-medium; top: 100vh;"
        >
          <div className="uk-container">
            <nav className="uk-navbar uk-navbar-container uk-navbar-transparent">
              <div className="uk-navbar-left">
                <div className="uk-navbar-item uk-padding-remove-horizontal uk-margin-left@m">
                  <Link className="logo" to="/">
                    <Image
                      className="uk-logo logo"
                      src={isVisible ? darkLogo : logo}
                      alt="Vindicia logo"
                    />
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

export default MiniSiteNavigation
