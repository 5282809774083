import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HeroWithoutImage from "../components/Hero/HeroWithoutImage"
import SEO, { seoPropsMapping } from "../components/seo"
import GatesWithForm from "../components/Resources/GatesWithForm.js"
import { withPreview } from "gatsby-source-prismic"

const Whitepapers = ({ data }) => {
  const resource_link = data.prismicWhitepapers.data.link
  //const download = resource_link ? resource_link.url : null;
  //const article_content = data.prismicWhitepapers.data.description;
  const is_gated = data.prismicWhitepapers.data.is_gated === true ? true : false

  const description = data.prismicWhitepapers.data.description.text
    ? data.prismicWhitepapers.data.description.text
    : ""

  const og_description =
    description.length < 160 ? description : description.substring(0, 160) + `...`
  const og_image = data.prismicWhitepapers.data.thumbnail.fixed.src

  const seoProps = seoPropsMapping(data.prismicWhitepapers.data, {
    seo_title: `Whitepapers: ${data.prismicWhitepapers.data.title.text}`,
    seo_description: og_description,
    image: og_image,
  })

  return (
    <Layout>
      <SEO {...seoProps} />
      <HeroWithoutImage title={`Resource Center / Whitepapers`} />
      <section className="uk-section">
        <div className="uk-container">
          <div className="" data-uk-grid>
            <div className="uk-width-expand">
              <h2>{data.prismicWhitepapers.data.title.text}</h2>
              <div
                className="prismic-content uk-margin-small-top"
                dangerouslySetInnerHTML={{ __html: data.prismicWhitepapers.data.description.html }}
              />
            </div>
            <div className="uk-width-1-3@m">
              {is_gated && resource_link ? (
                <GatesWithForm
                  resourceKey={data.prismicWhitepapers.uid}
                  slogan="Get the Whitepaper"
                  marketoFormId="1565"
                >
                  <h3>Thank you</h3>
                  {resource_link && (
                    <a
                      className="uk-button uk-button-large uk-button-primary uk-border-pill uk-scrollspy-inview uk-animation-slide-right-medium"
                      href={resource_link.url}
                      target={resource_link.target}
                    >
                      Download whitepaper <span uk-icon="download"></span>
                    </a>
                  )}
                </GatesWithForm>
              ) : (
                <div className="GatesWithForm">
                  {resource_link && (
                    <a
                      className="uk-button uk-button-large uk-button-primary uk-border-pill uk-scrollspy-inview uk-animation-slide-right-medium"
                      href={resource_link.url}
                      target={resource_link.target}
                    >
                      Download whitepaper <span uk-icon="download"></span>
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(Whitepapers)
export const infographicsDetail = graphql`
  query whitepapersDetail($uid: String) {
    prismicWhitepapers(uid: { eq: $uid }) {
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        is_gated
        description {
          html
          text
        }
        thumbnail {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        link {
          url
          target
        }
        title {
          html
          text
        }
      }
      uid
      last_publication_date
    }
  }
`
