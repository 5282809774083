import React from "react"

import { Link } from "gatsby"
//import { graphql, useStaticQuery } from "gatsby";
import { Background } from "../components/VindiciaComponents"
import Layout from "../components/layout"
import SEO from "../components/seo"
import heroImage from "../images/404-error-page.jpg"
//import { withPreview } from 'gatsby-source-prismic';
import { withUnpublishedPreview } from "gatsby-source-prismic"

import Author from "../templates/author-detail.js"
import Blog from "../templates/blog-detail"
import Whitepapers from "../templates/whitepapers-detail.js"
import Ebooks from "../templates/ebooks-detail.js"
import Datasheets from "../templates/datasheets-detail.js"
import Infographics from "../templates/infographics-detail.js"
import Video from "../templates/video-detail.js"
import Guide from "../templates/guide.js"
import Reports from "../templates/reports-detail.js"
import SusbcriptionManagementDetail from "../templates/subscription-management-detail.js"
import Product from "../templates/solutions.js"
import LandindPage from "../templates/landing-page.js"
import EventsPage from "../templates/events-detail.js"
import NewsPage from "../templates/news-detail.js"
import PressRelease from "../templates/press-release-detail.js"
import CustomTemplatePage from "../templates/custom-template.js"
import MiniSiteTemplatePage from "../templates/mini-site"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <header className="not-found uk-position-relative">
        <div className="">
          <Background src={heroImage} className="hero-bg" overlay="rgba(29, 38, 54, 0.43)">
            <div className="uk-container hero-copy uk-container-small uk-flex-auto uk-position-relative uk-margin-medium-top">
              <div
                className="uk-width-1-2@s"
                data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
              >
                <h1 className="uk-light">404 – page not found</h1>
                <p className="uk-h3 uk-light">
                  Oops! Looks like we have a broken link. While we sniff out the issue...
                </p>
                <Link
                  to="/"
                  className="uk-button uk-button-large uk-button-primary uk-border-pill"
                  data-uk-scrollspy-class="uk-animation-fade"
                >
                  Return to Homepage
                </Link>
              </div>
            </div>
          </Background>
        </div>
      </header>
    </Layout>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    product_page: Product,
    landing_page: LandindPage,
    events: EventsPage,
    news_entry: NewsPage,
    press_release_entry: PressRelease,
    authors: Author,
    blog_entry: Blog,
    datasheets: Datasheets,
    ebooks: Ebooks,
    infographics: Infographics,
    video: Video,
    guide: Guide,
    reports: Reports,
    subscription_management: SusbcriptionManagementDetail,
    whitepapers: Whitepapers,
    custom_template: CustomTemplatePage,
    mini_site: MiniSiteTemplatePage,
  },
})

/*
        blog_categories:
      client_categories:
      clients:
      faq_categories:
      faq_pages:
      glossary_entry:
      job_listing:
      job_role_category:
      partner_categories:
      partner_companies:
      privacy_policy_page:
      webinars:
  */

/*
  if (doc.type === 'about_page' && doc.uid === 'about-vindicia') return "/company/"+doc.uid;
  if (doc.type === 'events_pag' && doc.uid === 'upcoming-events') return "/company/"+doc.uid;
  if (doc.type === 'our_team_page' && doc.uid === 'management-team') return "/company/team";
  if (doc.type === 'awards_page' && doc.uid === 'awards') return "/company/"+doc.uid;
  if (doc.type === 'glossary_page' && doc.uid === 'vindicia-glossary') return "/technical-center/"+doc.uid;
  if (doc.type === 'client_services_page' && doc.uid === 'customer-deployment-solutions') return "/technical-center/"+doc.uid;
  if (doc.type === 'product_page' && doc.uid === 'marketone') return "/marketone";

  if (doc.type === 'subscription_management') return "/products/subscribe/" + doc.uid;
  if (doc.type === 'client_categories') return "/list-of-clients/" + doc.uid;
  if (doc.type === 'whitepapers') return "/resources/white-papers/" + doc.uid;
  if (doc.type === 'infographics') return "/resources/infographics/" + doc.uid;
  if (doc.type === 'ebooks') return "/resources/ebooks/" + doc.uid;
  if (doc.type === 'datasheets') return "/resources/datasheets/" + doc.uid;
  if (doc.type === 'blog_categories') return "/blog/category/" + doc.uid;
  if (doc.type === 'resource_categories') return "/resources/" + doc.uid;
  if (doc.type === 'job_role_category') return "/company/careers/";
  if (doc.type === 'faq_categories') return "/technical-center/faq/general-faq/";
  if (doc.type === 'product_page') return "/products/" + doc.uid;
  if (doc.type === 'privacy_policy_page') return "/" + doc.uid;
  if (doc.type === 'events') return "/company/events/"+doc.uid;
  if (doc.type === 'press_release_entry') return "/company/press-releases/"+doc.uid;
  if (doc.type === 'news_entry') return "/company/in-the-news/"+doc.uid;
  if (doc.type === 'job_listing') return "/company/careers/"+doc.uid;
  if (doc.type === 'faq_pages') return "/technical-center/faq/"+doc.uid;
  if (doc.type === 'glossary_entry') return "/technical-center/vindicia-glossary";
  if (doc.type === 'subscribe_plans') return "/products/"+doc.uid;
  */
