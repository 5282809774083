import MiniSiteLayout from "../components/mini-site-layout"
import SEO from "../components/seo"
import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"
import ContentType from "../components/MiniSite/ContentType"
import Hero from "../components/MiniSite/Hero"

const MiniSiteTemplatePage = ({ data }) => {
  const { uid, data: pageData } = data.prismicMiniSite

  return (
    <MiniSiteLayout>
      <SEO
        title={pageData.seo_title}
        description={pageData.seo_description}
        keywords={pageData.seo_keywords}
        hideFromIndexing={pageData.hide_from_indexing}
      />
      <div className="mini-site" style={{ "margin-bottom": "100px" }}>
        <Hero data={pageData} />
        <div className="default-theme">
          {pageData.body.map(section => {
            return <ContentType key={`section-${section.primary.section_id}`} section={section} />
          })}
        </div>
      </div>
    </MiniSiteLayout>
  )
}

export default withPreview(MiniSiteTemplatePage)

export const miniSiteQuery = graphql`
  query miniSiteQuery($uid: String) {
    prismicMiniSite(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        hide_from_indexing
        hero_button_label
        hero_button_link
        hero_subtitle {
          html
        }
        hero_title
        url_prefix
        body {
          ... on PrismicMiniSiteBodyCampaignPromo {
            primary {
              section_id
              title {
                html
              }
              description {
                html
              }
              cta_button_link {
                target
                url
              }
              cta_button_text
              section_name
              section_vimeo_id
              section_vimeo_height
              section_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicMiniSiteBody3Cards {
            primary {
              title {
                html
              }
            }
            items {
              buttol_label
              button_link {
                target
                url
              }
              title_card_field {
                html
              }
            }
            slice_type
          }
        }
      }
    }
  }
`
