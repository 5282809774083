import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/Product/Hero"
import Layout from "../components/layout"
import OneColumn from "../components/Content/OneColumn"
import CTABlock from "../components/Content/CTABlock"
import TwoColumnWithImage from "../components/Content/TwoColumnWithImage"
import TwoColumnWithVideo from "../components/Content/TwoColumnWithVideo"
import TwoColumnWithAccordion from "../components/Content/TwoColumnWithAccordion"
import FullWidthBanner from "../components/Content/FullWidthBanner"
import FullWidthBannerWithVideoOverlay from "../components/Content/FullWidthBannerWithVideoOverlay"
import ThreeColumnWithIcon from "../components/Content/ThreeColumnWithIcon"
import ThreeColumnGrid from "../components/Content/ThreeColumnGrid"
import ThreeColumnCards from "../components/Content/ThreeColumnCards"
import SliderWithBackground from "../components/Content/SliderWithBackground"
import Testimonials from "../components/Product/Testimonials"
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../components/VindiciaComponents"
import NumberFact from "../components/Content/NumberFact"
import RelatedMaterial from "../components/Content/RelatedMaterial"
import PromotionalComponent from "../components/Content/PromotionalComponent"
import ImageGallery from "../components/Content/ImageGallery"
import HoverBackgroundZoom from "../components/Content/HoverBackgroundZoom"
import TwoColumnWithImageSlider from "../components/Content/TwoColumnWithImageSlider"
import Form from "../components/Content/Form"
import FAQ from "../components/Content/FAQ"
import LogoCarousel from "../components/Content/LogoCarousel"
import FourColumnWithIcon from "../components/Content/FourColumnWithIcon"
import ThreeColumnIconCard from "../components/Content/ThreeColumnIconCard"
import OneColumnScroller from "../components/Content/OneColumnScroller"

const Product = ({ data }) => {
  const section = data.prismicProductPage.data
  const page = data.prismicProductPage.uid

  const contentType = section => {
    const type = section.slice_type
    switch (type) {
      case "1-column_container-small":
        return <OneColumn key={`section-${section.primary.section_id}`} data={section} />
      case "2-column":
        return <TwoColumnWithImage key={`section-${section.primary.section_id}`} data={section} />
      case "2-column-video":
        return <TwoColumnWithVideo key={`section-${section.primary.section_id}`} data={section} />
      case "2-column_with_accordion":
        return (
          <TwoColumnWithAccordion key={`section-${section.primary.section_id}`} data={section} />
        )
      case "full_width_banner":
        return <FullWidthBanner key={`section-${section.primary.section_id}`} data={section} />
      case "full_width_banner_with_video_overlay":
        return (
          <FullWidthBannerWithVideoOverlay
            key={`section-${section.primary.section_id}`}
            data={section}
          />
        )
      case "3-column_with_icon":
        return <ThreeColumnWithIcon key={`section-${section.primary.section_id}`} data={section} />
      case "3-column_grid":
        return <ThreeColumnGrid key={`section-${section.primary.section_id}`} data={section} />
      case "scroller_with_bg":
        return <SliderWithBackground key={`section-${section.primary.section_id}`} data={section} />
      case "3-column-cards":
        return (
          <ThreeColumnCards key={`section-${section.primary.section_id}`} data={section}>
            {section.items.map((item, i) => {
              const styles = {
                boxShadow: "none",
                borderRadius: "10px",
                backgroundColor: section.primary.card_background_color || "#fff",
              }

              return (
                <li key={`card-${i}`}>
                  <div
                    className="uk-card uk-card-body uk-card-small"
                    style={{ ...styles }}
                    data-uk-card
                  >
                    <div className="uk-card-media-top uk-text-center uk-margin-small-bottom">
                      {item.card_header_image.fixed && (
                        <Image
                          src={item.card_header_image.fixed.src}
                          srcSet={item.card_header_image.fixed.srcSet}
                          alt="Vindicia"
                        />
                      )}
                    </div>
                    <div className="uk-text-center">
                      <div dangerouslySetInnerHTML={{ __html: item.card_header_content.html }} />
                      <div dangerouslySetInnerHTML={{ __html: item.card_body_content.html }} />
                    </div>
                  </div>
                </li>
              )
            })}
          </ThreeColumnCards>
        )
      case "cta_block":
        return <CTABlock key={`section-${section.primary.section_id}`} data={section} />
      case "number_fact":
        return <NumberFact key={`section-${section.primary.section_id}`} data={section} />
      case "related_material_component":
        return <RelatedMaterial key={`section-${section.primary.section_id}`} data={section} />
      case "promotion_component":
        return <PromotionalComponent key={`section-${section.primary.section_id}`} data={section} />
      case "image_gallery":
        return <ImageGallery key={`section-${section.primary.section_id}`} data={section} />
      case "hover_background_zoom_block":
        return <HoverBackgroundZoom key={`section-${section.primary.section_id}`} data={section} />
      case "2-column_with_image_slider":
        return (
          <TwoColumnWithImageSlider key={`section-${section.primary.section_id}`} data={section} />
        )
      case "form":
        return <Form key={`section-${section.primary.section_id}`} data={section} />
      case "faq":
        return <FAQ key={`section-${section.primary.section_id}`} data={section} />
      case "logo_carousel":
        return <LogoCarousel key={`section-${section.primary.section_id}`} data={section} />
      case "4-column-icon":
        return <FourColumnWithIcon data={section} />
      case "3-column-icon-cards":
        return <ThreeColumnIconCard data={section} />
      case "1-column-repeatable":
        return <OneColumnScroller data={section} />

      default:
        return <></>
    }
  }

  return (
    <Layout uid={page}>
      <SEO
        title={section.seo_title}
        description={section.seo_description}
        keywords={section.seo_keywords}
        hideFromIndexing={section.hide_from_indexing}
      />
      <div className="default-theme">
        <Hero page={page} data={section} />
        {section.body.map(section => contentType(section))}
        {section.testimonial_quote.length && <Testimonials page={page} data={section} />}
      </div>
    </Layout>
  )
}

export default withPreview(Product)

export const productQuery = graphql`
  query productQuery($uid: String) {
    prismicProductPage(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        hide_from_indexing
        page_title
        color_theme
        hero_button_label
        hero_button_link
        hero_subtitle
        hero_title {
          text
          html
        }
        hero_image {
          alt
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        hero_video {
          url
        }
        testimonial_section_theme
        testimonial_quote {
          case_study
          copy
          source
          logo {
            fixed(width: 200) {
              ...GatsbyPrismicImageFixed_noBase64
            }
          }
        }
        url_prefix
        show_pop_up
        popup_by_click
        popup_title {
          html
        }
        popup_description {
          html
        }
        popup_button_title
        popup_button_link {
          target
          url
        }
        marketo_id
        popup_timeout
        body {
          ... on PrismicProductPageBody2Column {
            primary {
              section_id
              section_name
              section_theme
              section_background_color
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              cta_url {
                target
                url
              }
              cta_text
            }
            slice_label
            slice_type
          }
          ... on PrismicProductPageBody2ColumnWithImageSlider {
            primary {
              section_id
              section_name
              copy {
                html
              }
              cta_url {
                target
                url
              }
              cta_text
            }
            items {
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicProductPageBody2ColumnVideo {
            id
            slice_label
            slice_type
            primary {
              copy {
                html
              }
              cta_url {
                target
                url
              }
              cta_text
              section_id
              section_name
              section_theme
              section_background_color
              vimeo_id
            }
          }
          ... on PrismicProductPageBodyFullWidthBanner {
            primary {
              section_id
              section_name
              copy {
                html
              }
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              cta_url {
                url
                target
              }
              cta_text
              theme_color_section
            }
            slice_type
          }
          ... on PrismicProductPageBodyFullWidthBannerWithVideoOverlay {
            primary {
              section_id
              section_name
              copy {
                html
              }
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicProductPageBody2ColumnWithAccordion {
            primary {
              section_id
              section_name
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_label
            slice_type
            items {
              accordion_element_body {
                html
              }
              accordion_element_title
            }
          }
          ... on PrismicProductPageBody3ColumnWithIcon {
            primary {
              section_id
              section_name
            }
            items {
              icon_image {
                fixed(height: 50) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              short_copy {
                html
              }
              cta_link_url {
                target
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicProductPageBody1ColumnContainerSmall {
            primary {
              section_id
              section_name
              copy {
                html
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicProductPageBody3ColumnGrid {
            primary {
              section_id
              section_name
              section_title {
                text
              }
            }
            items {
              sub_mgmt_items {
                uid
                document {
                  ... on PrismicSubscriptionManagement {
                    data {
                      short_copy {
                        html
                      }
                      short_title
                      icon_image {
                        fixed(height: 60) {
                          ...GatsbyPrismicImageFixed_noBase64
                        }
                      }
                      url_prefix
                      second_url_prefix
                    }
                  }
                }
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicProductPageBody3ColumnCards {
            primary {
              section_id
              section_name
              background_color
              card_background_color
              section_title_centered {
                text
              }
            }
            items {
              card_body_content {
                html
              }
              card_header_content {
                html
              }
              card_header_image {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicProductPageBodyScrollerWithBg {
            id
            slice_type
            primary {
              section_name
              section_id
              background_image {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            items {
              slide_title
              slide_copy {
                html
              }
              slide_image_desktop {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              slide_image_mobile {
                alt
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicProductPageBodyCtaBlock {
            primary {
              section_id
              section_theme
              title {
                html
              }
              description {
                html
              }
              cta_button_link {
                target
                url
              }
              cta_button_text
              section_name
              section_background
              section_vimeo_id
              section_vimeo_height
              section_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicProductPageBodyNumberFact {
            primary {
              section_id
              section_name
              section_title {
                html
              }
              section_description {
                html
              }
            }
            slice_type
            items {
              number_field
              prefix_text {
                html
                raw
              }
              postfix_text {
                html
                raw
              }
              description_text {
                html
              }
            }
          }
          ... on PrismicProductPageBodyRelatedMaterialComponent {
            primary {
              section_id
              section_name
              section_title {
                html
              }
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
              }
              item_doc_title {
                html
              }
              link_item {
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicProductPageBodyPromotionComponent {
            primary {
              section_id
              section_name
              section_promo_title
              section_description {
                html
              }
            }
            slice_type
            items {
              item_title
              item_description {
                html
              }
              button_link {
                target
                url
              }
              button_label
              item_image {
                alt
                fluid(maxWidth: 760) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicProductPageBodyImageGallery {
            primary {
              section_id
              section_name
              section_description {
                html
              }
            }
            slice_type
            items {
              item_description {
                html
              }
              item_image {
                alt
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicProductPageBodyHoverBackgroundZoomBlock {
            primary {
              section_id
              section_name
            }
            slice_type
            items {
              heading {
                html
              }
              description {
                html
              }
              button_link {
                target
                url
              }
              button_link_text
              background {
                alt
                fluid(maxWidth: 760) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              overlay_color
            }
          }
          ... on PrismicProductPageBodyForm {
            primary {
              section_id
              section_name
              form_description {
                html
              }
              form_title {
                html
              }
              link {
                target
                url
              }
              link_title
              marketo_form_id
            }
            slice_label
            slice_type
          }
          ... on PrismicProductPageBodyFaq {
            id
            primary {
              section_id
              section_name
              button_link {
                target
                url
              }
              button_title
              description {
                html
              }
              heading {
                html
              }
            }
            items {
              answer
              question
            }
            slice_label
            slice_type
          }
          ... on PrismicProductPageBodyLogoCarousel {
            primary {
              section_id
              section_name
              title {
                html
              }
              description {
                html
              }
              button_text
              button_url {
                target
                url
              }
            }
            slice_type
            items {
              logo {
                fixed(width: 150) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicProductPageBody4ColumnIcon {
            slice_type
            primary {
              section_theme
              section_background_color
              section_heading {
                html
              }
              section_description {
                html
              }
            }
            items {
              title
              description {
                html
              }
              icon {
                alt
                fluid(maxWidth: 50) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicProductPageBody3ColumnIconCards {
            slice_type
            primary {
              section_theme
              section_background_color
              cards_background_color
              section_heading {
                html
              }
              section_description {
                html
              }
            }
            items {
              title
              description {
                html
              }
              icon {
                alt
                fluid(maxWidth: 50) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              link_url {
                target
                url
              }
              link_title
            }
          }
          ... on PrismicProductPageBody1ColumnRepeatable {
            slice_label
            slice_type
            primary {
              section_id
              section_theme
              section_background_color
              section_title {
                html
              }
              section_description {
                html
              }
            }
            items {
              copy_text {
                html
              }
              author {
                html
              }
              job_title {
                html
              }
              cta_text
              cta_url {
                url
                target
              }
            }
          }
        }
      }
    }
  }
`
