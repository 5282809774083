import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HeroWithoutImage from "../components/Hero/HeroWithoutImage"
import SEO, { seoPropsMapping } from "../components/seo"
import GatesWithForm from "../components/Resources/GatesWithForm.js"
import { withPreview } from "gatsby-source-prismic"

const Ebooks = ({ data }) => {
  const resource_link = data.prismicEbooks.data.link
  const is_gated = data.prismicEbooks.data.is_gated === true ? true : false
  const thumbnail = data.prismicEbooks.data.thumbnail.fixed

  const description = data.prismicEbooks.data.description.text
    ? data.prismicEbooks.data.description.text
    : ""

  const og_description =
    description.length < 160 ? description : description.substring(0, 160) + `...`
  const seoProps = seoPropsMapping(data.prismicEbooks.data, {
    seo_title: `eBooks: ${data.prismicEbooks.data.title.text}`,
    seo_description: og_description,
    image: thumbnail.src,
  })

  return (
    <Layout>
      <SEO {...seoProps} />
      <HeroWithoutImage title={`Resource Center / eBooks`} />
      <section className="uk-section">
        <div className="uk-container">
          <div className="" data-uk-grid>
            <div className="uk-width-expand">
              <h2>{data.prismicEbooks.data.title.text}</h2>
              <div
                className="prismic-content uk-margin-small-top"
                dangerouslySetInnerHTML={{ __html: data.prismicEbooks.data.description.html }}
              />
            </div>
            <div className="uk-width-1-3@m">
              {is_gated && resource_link ? (
                <GatesWithForm
                  resourceKey={data.prismicEbooks.uid}
                  slogan="Get the eBook"
                  marketoFormId="1567"
                >
                  <h3>Thank you</h3>
                  {resource_link && (
                    <a
                      className="uk-button uk-button-large uk-button-primary uk-border-pill uk-scrollspy-inview uk-animation-slide-right-medium"
                      href={resource_link.url}
                      target={resource_link.target}
                    >
                      Download eBook <span uk-icon="download"></span>
                    </a>
                  )}
                </GatesWithForm>
              ) : (
                <div className="GatesWithForm">
                  {resource_link && (
                    <a
                      className="uk-button uk-button-large uk-button-primary uk-border-pill uk-scrollspy-inview uk-animation-slide-right-medium"
                      href={resource_link.url}
                      target={resource_link.target}
                    >
                      Download eBook <span uk-icon="download"></span>
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(Ebooks)
export const infographicsDetail = graphql`
  query ebooksDetail($uid: String) {
    prismicEbooks(uid: { eq: $uid }) {
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type
        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        is_gated
        thumbnail {
          fixed(imgixParams: { ar: "2:1", fit: "crop", maxWidth: 1200 }) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        description {
          html
          text
        }
        link {
          url
          target
        }
        title {
          html
          text
        }
      }
      uid
      last_publication_date
    }
  }
`
