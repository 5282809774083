import React from "react"

const ThreeCards = ({ data }) => {
  const section = data.primary
  const items = data.items

  return (
    <section id={section.section_id || "three-cards"} className="uk-section">
      <div
        className="uk-container uk-text-center"
        data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
      >
        {section.title && (
          <div
            className="uk-text-center"
            dangerouslySetInnerHTML={{ __html: section.title.html }}
          />
        )}
        <div className="uk-flex uk-flex-wrap uk-flex-center" style={{ gap: "30px" }}>
          {items.map(item => {
            return (
              <div className="custom-card uk-card uk-card-body uk-flex uk-flex-column">
                <div
                  className="uk-card-title uk-text-left"
                  dangerouslySetInnerHTML={{ __html: item.title_card_field.html }}
                />
                <a
                  className="uk-button uk-button-primary uk-border-pill uk-margin-auto-top uk-text-nowrap uk-overflow-hidden uk-text-truncate"
                  href={item.button_link.url}
                  target={item.button_link.target}
                >
                  {item.buttol_label}
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ThreeCards
