import React from "react"

const Hero = ({ data }) => {
  return (
    <header
      className="uk-flex uk-flex-center uk-position-relative uk-dark uk-height-match"
      style={{ backgroundColor: "#fff" }}
      uk-height-viewport="offset-top: true"
    >
      <div
        className={`hero-copy uk-flex uk-flex-center uk-flex-middle uk-container uk-position-relative uk-margin-large-top uk-flex-row@m`}
      >
        <div
          className={`uk-width-3-4 uk-text-center`}
          data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
        >
          <h1 className="animated-title uk-text-bold">{data.hero_title}</h1>
          {data.hero_subtitle && (
            <div dangerouslySetInnerHTML={{ __html: data.hero_subtitle.html }} />
          )}
          <a
            href={data.hero_button_link}
            className="uk-button uk-button-large uk-button-primary uk-border-pill uk-margin-right uk-margin-small-bottom"
          >
            {data.hero_button_label}
          </a>
        </div>
      </div>
    </header>
  )
}

export default Hero
