import React from "react"
import ThreeCards from "./ThreeCards"
import CampaignPromo from "./CampaignPromo"

const ContentType = ({ section }) => {
  const type = section.slice_type

  switch (type) {
    case "3-cards":
      return <ThreeCards data={section} />
    case "campaign_promo":
      return <CampaignPromo data={section} />

    default:
      return <></>
  }
}

export default ContentType
