import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import SEO, { seoPropsMapping } from "../components/seo"
import moment from "moment"
// import heroImage from "../images/hero-news.jpg"
import { withPreview } from "gatsby-source-prismic"

const EventsPage = ({ data }) => {
  const eventData = data.prismicEvents.data
  const seo_description =
    eventData.body.text.length > 160
      ? `${eventData.body.text.substring(0, 160)}...`
      : eventData.body.text

  const seoProps = seoPropsMapping(eventData, {
    seo_title: `Event: ${eventData.title.text}`,
    seo_description,
    image: eventData.banner.fluid.src,
  })

  return (
    <Layout>
      <SEO {...seoProps} />
      <Header title={`Upcoming events`} />
      <section className="uk-section">
        <div className="uk-container">
          <div className="">
            <p className="uk-heading-small">{eventData.title.text}</p>
            <p>
              {moment(eventData.start_time).format("MMM D, YYYY")} -{" "}
              {moment(eventData.end_time).format("MMM D, YYYY")}
            </p>
            <div className="uk-width-expand">
              <div
                className="prismic-content uk-margin-small-top"
                dangerouslySetInnerHTML={{ __html: eventData.body.html }}
              />
              <a
                className="uk-button uk-button-large uk-button-primary uk-border-pill uk-margin"
                href={eventData.event_url.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {eventData.cta}
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(EventsPage)
export const eventsDetail = graphql`
  query eventsDetail($uid: String) {
    prismicEvents(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        title {
          html
          text
        }
        start_time
        date_text_string
        cta
        end_time
        body {
          html
          text
        }
        banner {
          alt
          copyright
          url
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
          alt
        }
        event_url {
          target
          url
        }
      }
    }
  }
`
